<template>
    <v-container class="mt-5">
        <!-- Header -->
        <v-row class="ma-0 pa-0 justify-center text-center">
            <v-col class="ma-0 pa-0" :cols="breaking ? 0 : 2"></v-col>
            <v-col class="ma-0 pa-0" :cols="breaking? 11 : 8">
                <h1 class="headline font-weight-bold mb-8 text-center">Das echte und einzig wahre Kochbuch</h1>
            </v-col>
            <v-col class="mb-10 pa-0" :cols="breaking ? 1 : 2">
                <v-btn
                    v-if="!breaking"
                    color="primary"
                    @click="logout"
                    outlined
                >Logout</v-btn>
                <v-icon
                    v-else
                    class="ma-0 pa-0"
                    @click="logout"
                >mdi-logout</v-icon>
            </v-col>
        </v-row>

        <!-- Upload -->
        <v-container>
            <v-container>
                <v-row justify="center">
                    <v-card class="col-lg-7" flat outlined>
                        <v-card-title>
                            <h4>PDF updaten</h4>
                        </v-card-title>
                        <v-card-text>
                            <v-file-input
                                    type="file"
                                    accept="application/pdf"
                                    @change="onUpload"
                                    v-model="pdfData"
                                    :clearable="false"
                                    :messages="pdfData ? [`Progress: ${uploadValue.toFixed()}%`] : []"
                                    :loading="pdfData && uploadValue.toFixed() !== '100'"
                            />
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-container>
        </v-container>
        <v-container class="mt-5 col-lg-7">
            <v-responsive v-if="!getErr" :aspect-ratio="3/4">
                <object v-if="pdf" :data="pdf" type="application/pdf" width="100%" height="100%" class="my-auto d-flex">
                    <iframe :src="`https://docs.google.com/viewer?url=${pdf}&embedded=true`" width="100%"></iframe>
                </object>
                <v-skeleton-loader
                        v-else
                        class="mx-auto"
                        max-width="300"
                        type="card"
                ></v-skeleton-loader>
            </v-responsive>
            <v-alert v-else-if="getErr === 'storage/object-not-found'" type="info">
                Noch keine Datei hochgeladen
            </v-alert>
            <v-alert v-else type="error">
                The file is currently unavailable ({{ getErr }})
            </v-alert>
        </v-container>
    </v-container>
</template>

<script>
import {auth} from "@/firebase/index.js";
import {signOut} from "firebase/auth";
import {getStorage, ref, getDownloadURL, uploadBytesResumable} from 'firebase/storage';

const filename = 'file.pdf';

export default {
    name: "UploadPage",
    data() {
        return {
            storage: null,
            storageRef: null,
            pdfData: null,
            pdf: null,
            uploadValue: 0,
            files: [],
            getErr: null,
        }
    },
    computed: {
      breaking() {
          return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
      }
    },
    created() {
        this.storage = getStorage();
        this.storageRef = ref(this.storage, filename);
        this.getUrl();
    },
    methods: {
        logout() {
            signOut(auth)
                .then(() => console.log('signed out'))
                .catch((error) => console.log(error))
        },
        onUpload() {
            this.pdf = null;
            // Create a root reference
            const storage = this.storage;
            const storageRef = ref(storage, filename);

            const uploadTask = uploadBytesResumable(storageRef, this.pdfData);
            uploadTask.on(`state_changed`, snapshot => {
                    this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                }, error => {
                    console.log(error.message)
                },
                () => {
                    this.uploadValue = 100;
                    this.getUrl();
                }
            );
        },
        getUrl() {
            // Get the download URL
            getDownloadURL(this.storageRef)
                .then((url) => {
                    this.getErr = null;
                    this.pdf = url;// Insert url into an <img> tag to "download"
                })
                .catch((error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    this.getErr = error.code
                    switch (error.code) {
                        case 'storage/object-not-found':
                            // File doesn't exist
                            break;
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect the server response
                            break;
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>