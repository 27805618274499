<template>
    <div>
        <div class="container">
            <v-card flat outlined>
                <v-card-title class="justify-center">
                    <h1 class="display-1">Login</h1>
                </v-card-title>
                <v-card-text class="ma-0 pl-10 pr-10 pb-0">
                    <v-form ref="login">
                        <v-text-field
                            label="Passwort"
                            name="password"
                            prepend-icon="mdi-lock"
                            type="password"
                            v-model="password"
                            :rules="[rules.required ,rules.noError]"
                        ></v-text-field>
                        <v-checkbox
                            label="Angemeldet bleiben"
                            name="remember"
                            v-model="remember"
                        ></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions class="mb-0 pl-10 pr-10">
                    <v-btn
                        width="100%"
                        color="primary"
                        @click="login"
                        outlined
                    >
                        Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
        <v-snackbar
            v-model="snackbar"
            timeout="3000"
            color="error"
        >{{ snackText }}</v-snackbar>
    </div>
</template>

<script>
import {auth} from "@/firebase/index.js";
import {
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence,
    indexedDBLocalPersistence
} from "firebase/auth";

const placeholderMail = 'a@a.de';

export default {
    name: 'LoginPage',
    data() {
        return {
            password: '',
            remember: false,
            rules: {
                required: value => !!value || 'Pflichtfeld',
                noError: () => !this.error || 'Falsches Passwort',
            },
            snackbar: false,
            snackText: null,
            error: false,
        }
    },
    methods: {
        login() {
            this.error = false;
            if (this.$refs.login.validate()) {
                // persistent login
                setPersistence(auth, this.remember ? indexedDBLocalPersistence : browserSessionPersistence)
                    .then(() => console.log('Persistence set'))
                    .catch((error) => console.log(error))

                // login
                signInWithEmailAndPassword(auth, placeholderMail, this.password)
                    .then(() => console.log('Login successful'))
                    .catch((error) => {
                        console.log(error.code)

                        // wrong password
                        if (error.code === 'auth/wrong-password') {
                            this.snackText = 'Falsches Passwort';
                            this.snackbar = true;

                            this.error = true;
                            this.$refs.login.validate();
                        }

                        // too many requests
                        if(error.code === 'auth/too-many-requests') {
                            this.snackText = 'Zu viele Anmeldeversuche. Versuche es später noch einmal.';
                            this.snackbar = true;
                        }
                    })
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container { /* center the login form horizontally and vertically */
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
