<template>
    <v-app>
        <v-main>
            <LoginPage v-if="!auth.currentUser"/>
            <UploadPage v-else/>
        </v-main>
    </v-app>
</template>

<script>
import LoginPage from './components/LoginPage';
import UploadPage from './components/UploadPage';
import {auth} from "@/firebase/index.js";

export default {
    name: 'App',

    components: {
        LoginPage,
        UploadPage
    },

    data: () => ({
        auth: auth
    }),
};
</script>
