import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBtJ0DW90v76kk8tUu6ay-UxsZuSezOymI",
    authDomain: "bohn-kochbuch.firebaseapp.com",
    projectId: "bohn-kochbuch",
    storageBucket: "bohn-kochbuch.appspot.com",
    messagingSenderId: "156477801076",
    appId: "1:156477801076:web:c8bb61d0702d5fe9608721"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export { firebaseApp, auth };